// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-band-tsx": () => import("./../../../src/pages/band.tsx" /* webpackChunkName: "component---src-pages-band-tsx" */),
  "component---src-pages-fotos-tsx": () => import("./../../../src/pages/fotos.tsx" /* webpackChunkName: "component---src-pages-fotos-tsx" */),
  "component---src-pages-geschichte-tsx": () => import("./../../../src/pages/geschichte.tsx" /* webpackChunkName: "component---src-pages-geschichte-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-presse-tsx": () => import("./../../../src/pages/presse.tsx" /* webpackChunkName: "component---src-pages-presse-tsx" */),
  "component---src-templates-gallery-tsx": () => import("./../../../src/templates/gallery.tsx" /* webpackChunkName: "component---src-templates-gallery-tsx" */)
}

